<template>
  <div class="animated fadeIn col-xl-6 offset-xl-3">
    <b-card>
      <div slot="header">Tag</div>
      <b-form-group>
        Share this tag with your friends!
        <b-input-group class="col-xl-4 pl-0">
          <b-form-input
            @focus.native="$event.target.select()"
            type="text"
            :value="'https://carl.gg'+$route.fullPath"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant
              id="copypasted"
              v-b-tooltip.hover
              title="Copy link"
              v-clipboard:copy="'https://carl.gg'+$route.fullPath"
              class="mr-2"
            >
              <font-awesome-icon :icon="['fa', 'copy']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <hr />

        <b-row>
          <b-col>
            <h4>Description</h4>

            <b-input-group v-if="tag_owner">
              <b-form-textarea
                rows="6"
                type="text"
                pattern="[a-z0-9-_]{1,5000}"
                placeholder="You own this tag! Enter some neat descriptive stuff"
                class="col-12"
                v-model="description"
              ></b-form-textarea>
            </b-input-group>
            <div v-else>
              <div v-if="!description">
                <i>No description provided</i>
              </div>
              <div v-else>{{description}}</div>
            </div>
          </b-col>
        </b-row>

        <hr />
        <h4>Name</h4>

        <b-input-group>
          <div class="input-group-prepend">
            <div class="input-group-text">!</div>
          </div>
          <b-form-input type="text" pattern="[a-z0-9-_]{1,50}" class="col-12" v-model="tag_name"></b-form-input>
        </b-input-group>
        <hr />
        <h4>Content</h4>
        <b-input-group>
          <b-form-textarea
            rows="6"
            type="text"
            pattern="[a-z0-9-_]{1,5000}"
            placeholder="Enter some tag content"
            class="col-12"
            v-model="tag_content"
          ></b-form-textarea>
        </b-input-group>
        <div v-if="scary">
          ⚠This tag tries to execute the following command:
          <strong>!{{scary}}⚠</strong>
        </div>
        <hr />

        <EmbedBuilder ref="embed_builder"></EmbedBuilder>
      </b-form-group>
      <div slot="footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;padding-left:12px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto px-1">
              <b-button variant="danger" v-on:click="unlistTag" class="mr-1" v-if="tag_owner">Unlist</b-button>
              <b-button
                variant="primary"
                v-on:click="updateTag"
                class="ml-1"
                v-if="tag_owner"
              >Update description</b-button>
            </b-col>
            <b-col cols="auto" class="px-2">
              <b-form-select id="server_select" v-model="server" value="Please select">
                <option :value="null">No server selected</option>
                <option v-for="g in guilds" :key="g.position" :value="g.id">{{g.name}}</option>
              </b-form-select>
            </b-col>

            <b-col cols="auto" class="px-2">
              <b-button variant="success" v-on:click="importTag">Import</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import EmbedBuilder from "@/views/oc/EmbedBuilder";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "SharedTag",
  components: {
    ClientTable,
    Event,
    cSwitch,
    EmbedBuilder
  },
  notifications: {
    added_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag imported"
    },
    edited_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag edited"
    },
    deleted_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag deleted"
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    },
    no_spaces_thank_you: {
      type: VueNotifications.types.warn,
      title: "Bad tag",
      message:
        "Tags don't really work when they have spaces added, consider replacing them with dashes or underscores"
    }
  },
  data: function() {
    return {
      tag_content: "",
      tag_name: "",
      description: "",
      tag_owner_id: null,
      scary: null,
      server: null
    };
  },
  metaInfo() {
    return {
      title: "Shared tag",
      meta: [
        { vmid: "description", name: "description", content: "The home page" }
      ]
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/tags")
      .then(r => {
        this.tag_content = r.data.content;
        this.tag_name = r.data.name;
        this.description = r.data.description;
        this.tag_owner_id = r.data.owner_id;
        var embed_var = JSON.parse(r.data.embed);
        this.$refs.embed_builder.apply_json(embed_var);
        this.$refs.embed_builder.show_embed = Object.keys(embed_var).length > 1;
        this.scary = r.data.scary;
      })
      .catch(e => {
        return this.$router.push("/404");
      });
    next();
  },
  methods: {
    importTag() {
      if (this.server == null) {
        return;
      }
      return this.axios
        .put("/api/v1/servers/" + this.server + "/tags", {
          name: this.tag_name,
          content: this.tag_content,
          restricted: false,
          nsfw: false,
          author: this.$store.state.discord_user.id,
          embed: this.$refs.embed_builder.full_json
        })
        .then(r => {
          return this.added_tag();
        });
    },
    unlistTag() {
      this.axios
        .delete("/api/v1/sharedtags/" + this.$route.params.tag_id)
        .then(r => {
          this.added_tag({ message: "Tag unlisted, redirecting..." });
          setTimeout(() => {
            this.$router.push("/servers");
          }, 2000);
        });
    },
    updateTag() {
      this.axios
        .patch("/api/v1/sharedtags/" + this.$route.params.tag_id, {
          description: this.description
        })
        .then(r => this.added_tag({ message: "Description updated" }));
    }
  },
  computed: {
    tag_owner() {
      return (
        this.$store.state.discord_user.id == this.tag_owner_id ||
        this.$store.state.discord_user.id == this.tag_sharer_id
      );
    },
    guilds() {
      return this.$store.state.discord_guilds.filter(
        x => ((x.permissions & 40) !== 0 || x.owner) && x.link == undefined
      );
    }
  },
  created: function() {
    this.axios.get("/api/v1/tags/" + this.$route.params.tag_id).then(r => {
      this.tag_content = r.data.content;
      this.tag_name = r.data.name;
      this.description = r.data.description;
      this.tag_owner_id = r.data.owner_id;
      this.tag_sharer_id = r.data.sharer;
      var embed_var = JSON.parse(r.data.embed);
      this.$refs.embed_builder.apply_json(embed_var);
      this.$refs.embed_builder.show_embed = Object.keys(embed_var).length > 1;
      this.scary = r.data.scary;
      document.title = "Import tag " + this.tag_name;
    });
  }
};
</script>

